<div *ngIf="avatars?.length" class="avatar-group sl-flexbox-row sl-flexbox-align-center {{ displayMode }}">
	<sl-avatar 	*ngIf="avatars.length > avatarsToDisplay"
				[rawText]="'+' + (avatars.length - (avatarsToDisplay - 1))"
				styleClass="more-avatar"
				[size]="size"
				(mouseenter)="onMoreAvatarMouseEnter($event)"
				(mouseleave)="onMoreAvatarMouseLeave($event)">
	</sl-avatar>
	<sl-avatar 	*ngFor="let avatar of (avatars | slice:0:((avatars.length > avatarsToDisplay) ? avatarsToDisplay - 1 : avatarsToDisplay)) | reverse; let i = index;"
				[value]="avatar"
				[size]="size"
				[propToHash]="propToHash"
				[propToDisplay]="propToDisplay"
				(mouseenter)="onAvatarMouseEnter($event, avatar)"
				(mouseleave)="onAvatarMouseLeave($event, avatar)">
	</sl-avatar>
</div>
