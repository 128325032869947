import { NgFor, NgIf, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, output } from '@angular/core';

import { ReversePipe } from '@@shared/reverse-pipe';

import { AvatarComponent } from '../avatar/avatar.component';

@Component({
	selector: 'sl-avatars',
	templateUrl: './avatars.component.html',
	styleUrl: './avatars.component.scss',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NgIf,
		NgFor,
		SlicePipe,
		AvatarComponent,
		ReversePipe
	],
})
export class AvatarsComponent {
	@Input() avatars: any[];
	@Input() size = 24;
	@Input() propToHash: string;
	@Input() propToDisplay: string;
	@Input() avatarsToDisplay: number = 5;
	@Input() displayMode: 'ltr' | 'rtl' = 'rtl';

	moreAvatarsEnter = output<MouseEvent>();
	readonly moreAvatarsLeave = output<MouseEvent>();

	readonly avatarsEnter = output<{ event: MouseEvent; avatar: any }>();
	readonly avatarsLeave = output<{ event: MouseEvent; avatar: any }>();


	onAvatarMouseEnter(event: MouseEvent, avatar: any): void {
		this.avatarsEnter.emit({ event, avatar });
	}
	onAvatarMouseLeave(event: MouseEvent, avatar: any): void {
		this.avatarsLeave.emit({ event, avatar });
	}

	onMoreAvatarMouseEnter(event: MouseEvent): void {
		this.moreAvatarsEnter.emit(event);
	}
	onMoreAvatarMouseLeave(event: MouseEvent): void {
		this.moreAvatarsLeave.emit(event);
	}
}
